<template>
  <v-theme-provider :dark="dark">
    <v-row>
      <v-col cols="12" md="6">
        <base-info-card :title="strTitle" :text-color="strTextColor" pace="6">
          <slot />
        </base-info-card>
      </v-col>

      <v-col cols="12" md="6">
        <template
          v-for="(
            { strIcon, strIconWhite, arrTexts }, i
          ) in m_arrBusinessContacts"
        >
          <base-avatar-card
            :key="i"
            :src="strTextColor === 'white' ? strIconWhite : strIcon"
            :outlined="false"
            :text-color="strTextColor"
            color="transparent"
            horizontal
            space="0"
          >
            <template v-for="(strText, j) in arrTexts" class="d-flex">
              <div :key="j">
                {{ strText }}
              </div>
            </template>
          </base-avatar-card>

          <v-divider
            v-if="i + 1 !== m_arrBusinessContacts.length"
            :key="`divider-${i}`"
            class="my-2"
            :color="strTextColor"
          />
        </template>
      </v-col>
    </v-row>
  </v-theme-provider>
</template>

<script>
import BusinessDetails from "@/mixins/business-details";
import TextClass from "@/mixins/text-class";

export default {
  name: "KDABusinessCardRow",

  mixins: [BusinessDetails, TextClass],

  props: {
    dark: Boolean,
    strTitle: {
      type: String,
      default: "ABOUT US",
    },
    strTextColor: String,
  },

  computed: {
    m_arrBusinessContacts() {
      return this.g_arrBusinessContacts;
    },
  },
};
</script>
